import AppConstants from "../components/AppConstants";

let authToken =  localStorage.getItem(AppConstants.JWT);

const config = {
    headers: {Authorization: `Token ${authToken}`},
  };
  console.log("Config:", config);
export default config;

