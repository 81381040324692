

import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Popup from "reactjs-popup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { MdOutlineFileUpload } from "react-icons/md";
import "./faculty.css";
import FacultyComponent from "./component/faculty_component";
import axios from "../../../../network/api";
import config from "../../../../network/auth";
import { baseUrl } from "../../../../network/constants/constants";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Spinner } from "react-bootstrap";
import AppConstants from "../../../AppConstants";

const Faculty = () => {
  const [loading, setLoading] = useState(false);
  const [facultyDetails, setFacultyDetails] = useState([]);
  const [newFaculty, setNewFaculty] = useState({
    faculty_name: "",
    faculty_description: "",
    faculty_referral_id: "",
    faculty_expertise: "",
    faculty_referral_count: "",
    faculty_image: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [validationErrors, setValidationErrors] = useState({
    faculty_name: "",
    faculty_description: "",
    faculty_expertise: "",
    faculty_image: "",
  });
  const [selectedFileName, setSelectedFileName] = useState("");

  const validateFaculty = () => {
    const errors = {};
  
  
    if (!newFaculty.faculty_name) {
      errors.faculty_name = "Faculty name is required";
    }
  

    if (!newFaculty.faculty_description) {
      errors.faculty_description = "Description is required";
    } else if (newFaculty.faculty_description.length < 8) {
      errors.faculty_description = "Description must be at least 8 characters long";
    }
  

    if (!newFaculty.faculty_expertise) {
      errors.faculty_expertise = "Expertise is required";
    } else if (newFaculty.faculty_expertise.length < 8) {
      errors.faculty_expertise = "Expertise must be at least 8 characters long";
    }
  
    
   
  
    return errors;
  };
  
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFaculty({
      ...newFaculty,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewFaculty({
      ...newFaculty,
      faculty_image: file,
    });
    setSelectedFileName(file.name);
  };

  const handleSubmit = (event, close) => {
    event.preventDefault();
    const errors = validateFaculty();
  setValidationErrors(errors);

  if (Object.keys(errors).length === 0) {
    handleAddFaculty(close);
  }
  };

  // delete faculty
  const deleteFaculty = (facultyId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/faculties/${facultyId}/delete/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setFacultyDetails((prevFaculty) => prevFaculty.filter((faculty) => faculty.id !== facultyId));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Delete Faculty Error:", error);
        setLoading(false);
      });
  };

  // post faculty
  const handleAddFaculty = (close) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("faculty_name", newFaculty.faculty_name);
    formData.append("faculty_referral_id", newFaculty.faculty_referral_id);
    formData.append("faculty_expertise", newFaculty.faculty_expertise);
    formData.append("faculty_referral_count", newFaculty.faculty_referral_count);
    formData.append("faculty_description", newFaculty.faculty_description);
    if (newFaculty.faculty_image) {
      formData.append("faculty_image", newFaculty.faculty_image);
    }

    axios
      .post(`${baseUrl}app/faculties/upload/`, formData, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        fetchFaculty(currentPage);
        setNewFaculty({
          faculty_name: "",
          faculty_description: "",
          faculty_referral_id: "",
          faculty_expertise: "",
          faculty_referral_count: "",
          faculty_image: null,
        });
        setSelectedFileName("");
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("Add Faculty Error:", error);
        setLoading(false);
      });
  };

  // put faculty
  const handleEditFaculty = (facultyId, editedFaculty, close) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("faculty_name", editedFaculty.faculty_name);
    formData.append("faculty_referral_id", editedFaculty.faculty_referral_id);
    formData.append("faculty_expertise", editedFaculty.faculty_expertise);
    formData.append("faculty_referral_count", editedFaculty.faculty_referral_count);
    formData.append("faculty_description", editedFaculty.faculty_description);
    if (editedFaculty.faculty_image instanceof File) {
      formData.append("faculty_image", editedFaculty.faculty_image);
    }
    
    axios
      .put(`${baseUrl}app/faculties/${facultyId}/edit/`, formData, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        fetchFaculty(currentPage);
        setSelectedFileName("");
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error("Edit Faculty Error:", error);
        setLoading(false);
      });
  };

  // Fetch faculty
  const fetchFaculty = (page) => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/faculties/?page=${page}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setFacultyDetails(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
        setCurrentPage(page);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Fetch Faculty Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFaculty(currentPage);
  }, [currentPage]);

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      fetchFaculty(pageNumber);
    }
  };

  const renderPaginationControls = () => {
    let paginationHtml = [];

    // previous button
    if (currentPage > 1) {
      paginationHtml.push(
        <button key="prev" onClick={() => goToPage(currentPage - 1)}>
          <ArrowBackIosIcon />
        </button>
      );
    }

    // page numbers
    for (let i = 1; i <= totalPages; i++) {
      if (i === currentPage) {
        paginationHtml.push(<span key={i}>{i}</span>);
      } else {
        paginationHtml.push(
          <button key={i} onClick={() => goToPage(i)}>
            {i}
          </button>
        );
      }
    }

    // next button
    if (currentPage < totalPages) {
      paginationHtml.push(
        <button key="next" onClick={() => goToPage(currentPage + 1)}>
          <ArrowForwardIosIcon />
        </button>
      );
    }

    return paginationHtml;
  };

  return (
    <div className="main-container">
      <Container fluid>
      {loading ? (
          <Spinner className="loader-spinner" />
        ) : (
        <div className="faculty-screen-container">
          {facultyDetails.map((faculty) => (
            <FacultyComponent
              key={faculty.faculty_id}
              id={faculty.faculty_id}
              name={faculty.faculty_name}
              photo={faculty.faculty_image}
              description={faculty.faculty_description}
              referral_id={faculty.faculty_referral_id}
              expertise={faculty.faculty_expertise}
              referral_count={faculty.faculty_referral_count}
              onEditFaculty={handleEditFaculty}
              onDelete={deleteFaculty}
            />
          ))}
          <Popup
            className="add-popup-container"
            trigger={<button className="btn-add">+ Add Faculty Details</button>}
            modal
            nested
          >
            {(close) => (
              <div className="add-course-div">
                <form onSubmit={(e) => handleSubmit(e, close)}>
                  <Box className="popup-box">
                    <div className="heading">Add Faculty Details</div>
                    <div className="course-container">
                      <TextField
                        className="textfields"
                        name="faculty_name"
                        label="Name"
                        variant="outlined"
                        size="small"
                        value={newFaculty.faculty_name}
                        onChange={handleInputChange}
                        error={!!validationErrors.faculty_name}
                        helperText={validationErrors.faculty_name}
                      />
                      <TextField
                        className="textfields"
                        name="faculty_expertise"
                        label="Expertise"
                        variant="outlined"
                        size="small"
                        value={newFaculty.faculty_expertise}
                        onChange={handleInputChange}
                        error={!!validationErrors.faculty_expertise}
                        helperText={validationErrors.faculty_expertise}
                      />
                      <TextField
                        className="textfields"
                        name="faculty_description"
                        label="Description"
                        variant="outlined"
                        multiline
                        rows={3}
                        size="small"
                        value={newFaculty.faculty_description}
                        onChange={handleInputChange}
                        error={!!validationErrors.faculty_description}
                        helperText={validationErrors.faculty_description}
                      />
                      <span>Faculty Image(optional)</span>
                      <label className="add-file-upload">
                        <input
                          className="add-img-file"
                          id="file"
                          type="file"
                          onChange={handleFileChange}
                        />
                        <MdOutlineFileUpload className="upload-icon" />
                      </label>
                      {selectedFileName && (
                    <div className="file-name-display">
                      Selected File: {selectedFileName}
                    </div>
                  )}
                      <span className="error-text">{validationErrors.faculty_image}</span>
                    </div>
                    <div className="submit-banner-button-container">
                      <button type="submit">Submit</button>
                      <button type="button" onClick={close}>
                        Cancel
                      </button>
                    </div>
                  </Box>
                </form>
              </div>
            )}
          </Popup>
        </div>
        )}
        {totalPages > 1 && (
            <div className="pagination-container">
              <div id="pagination">{renderPaginationControls()}</div>
            </div>
          )}
      </Container>
    </div>
  );
};

export default Faculty;

