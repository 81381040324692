import "./login.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap/";
import Logo from "./../../assets/WINSCORE.svg";
import { TextField, Button } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import axios from "../../network/api";
import { baseUrl } from "../../network/constants/constants";

function Login() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [unauthorizedError, setUnauthorizedError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setUnauthorizedError(""); 
    setEmailError("")
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!regex.test(email)) {
      setEmailError("Invalid Email");
    } else {
      setEmailError("");
    }
    return regex.test(email);
  };

  const PostLogin = (props) => {
    if (!validateEmail(email)) return; 
    setLoading(true);
    setUnauthorizedError("");
    const payload = {
      email: email,
    };

    console.log("payload: " + JSON.stringify(payload));

    axios
      .post(baseUrl + "auth/adminlogin/", payload)
      .then((response) => {
        console.log("<><><><><><>" + JSON.stringify(response.data));
        setLoading(false);
        const dataToSend = { "email": email };
        navigate("../otp", { state: { data: dataToSend }, replace: true });
      })
      .catch((error) => {
        setLoading(false);
        
      if (error.response) {
        setUnauthorizedError("You do not have permission to access. Please check your email.");
      } else {
        console.log("Error:", error);
      }
      });
  };

  return (
    <section id="login">
      <div className="content">
        <div className="brand-container">
          <img alt="logo" src={Logo} className="logo"/>
          <div className="business-name"><span className="win">WIN</span>SCORE Admin Panel</div>

        </div>
        <Card className="login-card card mt-5 rounded-4">
          <div className="login-title">Login</div>

          <TextField
            id="email"
            className="mt-4"
            label="Email"
            variant="outlined"
            size="small"
            type="email"
            value={email}
            onChange={handleEmail}
            error={!!emailError || !!unauthorizedError}
            helperText={emailError || unauthorizedError || ""}
          />

          <Button
            variant="contained"
            className="login-btn mt-5 mb-3"
            onClick={PostLogin}
          >
            Submit
          </Button>
          <div className="spinner-container">
          <TailSpin
          className="mt-5"
            height="30"
            width="30"
            radius="1"
            color="#796dec"
            ariaLabel="loading"
            visible={loading}
          />
          </div>
        </Card>
      </div>

      <a href="tel:" className="contact">ⓘ Contact Us</a>
    </section>
  );
}

export default Login;




