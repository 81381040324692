import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Popup from "reactjs-popup";
import { baseUrl } from "../../../../network/constants/constants";
import axios from "../../../../network/api";
import Progress from "./components/progress/progress";
import "./course_progres.css";
import StudentForm from "./components/student_form/student_form";
import AppConstants from "../../../AppConstants";
import Autocomplete from "@mui/material/Autocomplete";

const CourseProgress = () => {
  const [loading, setLoading] = useState(false);
  const [studentDetails, setStudentDetails] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [studentUpload, setStudentUpload] = useState({
    course: "",
    price: "",
    excel: null,
    fileName: "",
    referral: "",
    institution: "",
  });
  const [openStudent, setOpenStudent] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const [institutionList, setInstitutionList] = useState([]);

  const handleIndividualStudentSubmit = (formState) => {
    setLoading(true);
    const form = new FormData();

    form.append("student_name", formState.studentName);
    form.append("student_phone", formState.studentPhone);
    form.append("student_email", formState.studentEmail);
    form.append("student_address", formState.studentAddress);
    form.append("student_class", formState.class);
    form.append("student_parent_phone", formState.parentPhone);
    form.append("student_parent_email", formState.parentEmail);
    form.append("student_institution", formState.studentInstitution);
    form.append("course_id", formState.course);
    form.append("student_referral_id", formState.referralId)

    if (isEdit) {
      editData(form);
    } else {
      submitData(form);
    }
  };

  const submitData = (form) => {
    axios
      .post(`${baseUrl}app/user-student/`, form, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
        },
      })
      .then((response) => {
        reloadFunction();
        alert("Student added successfully");

      })
      .catch((error) => {
        console.error("Student Error:", error);
        alert(error)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editData = (form) => {
    const id = selectedStudent?.row.id;
    axios
      .patch(`${baseUrl}app/admin/student-edit/${id}/`, form, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          
        },
      })
      .then((response) => {
        reloadFunction();
        alert("Student edited successfully");
      })
      .catch((error) => {
        console.error("Student Error:", error);
        alert(error)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditClick = (students = null) => {
    setIsEdit(true);
    setSelectedStudent(students);
    setOpenStudent(true);
  };

  const handleStudentClose = () => {
    setIsEdit(false);
    setOpenStudent(false);
    setSelectedStudent(null)
  };

  const reloadFunction = async () => {
    setLoading(true);
    try {
      let allPurchasingData = [];
      let nextPageUrl = `${baseUrl}app/student-list/`;

      while (nextPageUrl) {
        const response = await axios.get(nextPageUrl, {
          headers: {
            Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
            // "Content-Type": "multipart/form-data",
          },
        });
        allPurchasingData = [...allPurchasingData, ...response.data.results];
        nextPageUrl = response.data.next;
      }
      setStudentDetails(allPurchasingData);
    } catch (error) {
      console.error("Error fetching purchased course list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddStudent = () => {
    setOpenStudent(true);
  };



  useEffect(() => {
    reloadFunction();
    fetchCourseList();
    fetchInstitution();
  }, []);

  const columns = [
    { field: "student_id", headerName: "ID", width: 100 },
    { field: "student_name", headerName: "Student Name", width: 270 },
    { field: "student_class", headerName: "Student Class", width: 100 },
    { field: "student_email", headerName: "Student Email", width: 150 },
    { field: "student_phone", headerName: "Student Phone", width: 250 },
    { field: "student_address", headerName: "Student Address", width: 250 },
    {
      field: "student_referral_id",
      headerName: "Student Referral Id",
      width: 250,
    },
    { field: "student_parent_phone", headerName: "Parent Phone", width: 250 },
    { field: "student_parent_email", headerName: "Parent Email", width: 250 },
    {
      field: "student_institution_name",
      headerName: "Institution Name",
      width: 250,
    },
    {
      field: "student_institution_email",
      headerName: "Institution Email",
      width: 150,
    },
    {
      field: "progress",
      headerName: "Exam Progress",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          onClick={() => {
            setSelectedRow(params.row);
            setOpen(true);
          }}
        >
          <VisibilityIcon />
        </Button>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <div className="action-btn">
          <Button
            style={{ marginRight: "5px" }}
            variant="outlined"
            color="primary"
            onClick={() => handleEditClick(params)}
          >
            <EditIcon />
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(params.row);
            }}
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
    },
  ];

  const fetchCourseList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/courses/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setCourseList(response.data.results);
      })
      .catch((error) => {
        console.error("Error fetching faculty list:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentUpload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setStudentUpload((prev) => ({
        ...prev,
        excel: file,
        fileName: file.name,
      }));
    }
  };
  const handleSubmit = async (e, close) => {
    e.preventDefault();
  
    // Validation checks
    if (!studentUpload.course) {
      alert("Please select a course.");
      return;
    }
  
    if (!studentUpload.price) {
      alert("Please enter the course price.");
      return;
    }
  
    if (!studentUpload.excel) {
      alert("Please upload a file.");
      return;
    }
  
    // Prepare form data
    const formData = new FormData();
    formData.append("course_id", studentUpload.course);
    formData.append("amount", studentUpload.price);
    formData.append("file", studentUpload.excel);
    formData.append("referral_id", studentUpload.referral);
    formData.append("institution_id", studentUpload.institution);
  
    try {
      // Send the POST request with authorization and proper headers
      const response = await axios.post(`${baseUrl}app/upload/`, formData, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob", // Ensure response is received as a Blob for file saving
      });
  
     
      setStudentUpload({
        course: "",
        price: "",
        excel: "",
        referral:"",
        institution:"",
      });
  

      reloadFunction();
      close();
  
      // Save the received file as an Excel file
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileSaver = require("file-saver");
      fileSaver.saveAs(blob, "excel.xlsx");
  
      console.log("File saved successfully.");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("An error occurred while uploading the file. Please try again.");
    }
  };
  
  const handleDeleteClick = (student) => {
    setStudentToDelete(student.id);
    setShowDeletePopup(true);
  };

  const confirmDelete = () => {
    if (studentToDelete) {
      deleteService(studentToDelete);
      setStudentToDelete(null);
      setShowDeletePopup(false);
    }
  };

  const deleteService = (studentId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/student-delete/${studentId}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
        
        },
      })
      .then((response) => {
        reloadFunction();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error deleting service:", error);
        setLoading(false);
      });
  };

  const fetchInstitution = () => {
    setLoading(true);
    axios
      .get(baseUrl + `app/institution-list-create/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
         
        },
      })
      .then((response) => {
        setInstitutionList(response.data);
      })
      .catch((error) => {
        console.error("Institution Error:", error);
        setLoading(false);
      });
  };
 const simplifiedInstitution = institutionList.map((item) => ({
    id: item.id,
    institution_name: item.institution_name,
  }));
  return (
    <div className="main-container">
      <button className="btn-stu-add" onClick={handleAddStudent}>
        + Add Student
      </button>
      <StudentForm
        open={openStudent}
        onClose={handleStudentClose}
        onSubmit={handleIndividualStudentSubmit}
        students={isEdit ? selectedStudent?.row : null}
        institutionList={institutionList}
        courseList={courseList}
      />
      <Popup
        className="add-popup-container"
        trigger={<button className="btn-bulk-add">+ Add Bulk Student</button>}
        modal
        nested
      >
        {(close) => (
          <div className="add-course-div">
            <form onSubmit={(e) => handleSubmit(e, close)}>
              <Box className="popup-box">
                <div className="heading">Add Student</div>
                <div className="course-container">
                  <select
                    name="course"
                    className="form-control"
                    value={studentUpload.course}
                    onChange={handleChange}
                  >
                    <option value="">Select Course</option>
                    {courseList.map((option) => (
                      <option key={option.course_id} value={option.course_id}>
                        {option.course_name}
                      </option>
                    ))}
                  </select>
                  <TextField
                    className="textfields"
                    name="price"
                    label="Price"
                    variant="outlined"
                    size="small"
                    value={studentUpload.price}
                    onChange={handleChange}
                  />
                   <TextField
                    className="textfields"
                    name="referral"
                    label="Referral Id"
                    variant="outlined"
                    size="small"
                    value={studentUpload.referral}
                    onChange={handleChange}
                  />
                   <Autocomplete
                  className="textfields"
                  disablePortal
                  options={simplifiedInstitution}
                  getOptionLabel={(option) => option.institution_name}
                  onChange={(event, value) => {
                    setStudentUpload((prevData) => ({
                      ...prevData,
                      institution: value ? value.id : "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Institution"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
                  <label for="file-upload" class="custom-file-upload">
                    Upload Excel
                  </label>
                  <input
                  className="excel-upload"
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                  />
                  {studentUpload.fileName && (
                    <p>Selected File: {studentUpload.fileName}</p>
                  )}
                </div>
                <div className="submit-banner-button-container">
                  <button type="submit">Submit</button>
                  <button type="button" onClick={close}>
                    Cancel
                  </button>
                </div>
              </Box>
            </form>
          </div>
        )}
      </Popup>

      <TabContext>
        <Box className="box-container">
          <DataGrid
            className="grid-data"
            pageSize={20}
            autoHeight
            rows={studentDetails}
            columns={columns}
            getRowId={(row) => row.student_id}
            loading={loading}
          />
        </Box>
        <Popup
          className="popup-container"
          contentStyle={{
            borderRadius: "20px",
            backgroundColor: "white",
          }}
          open={showDeletePopup}
          onClose={() => setShowDeletePopup(false)}
          modal
          nested
        >
          {(close) => (
            <div className="delete-img-div">
              <h3 className="delete-img-heading">Are you sure?</h3>
              <p className="delete-img-p">Do you really want to delete?</p>
              <div className="delete-btn-div">
                <button onClick={confirmDelete}>Delete</button>
                <button onClick={close}>Cancel</button>
              </div>
            </div>
          )}
        </Popup>

        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle className="exam-progress-head">
            Exam Progress
          </DialogTitle>
          <DialogContent>
            {selectedRow && <Progress studentId={selectedRow.student_id} />}
          </DialogContent>
          <DialogActions>
            <button className="prg-cls" onClick={handleClose}>
              Close
            </button>
          </DialogActions>
        </Dialog>
      </TabContext>
    </div>
  );
};

export default CourseProgress;
