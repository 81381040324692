import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { Box, TextField } from "@mui/material";

const InstitutionForm = ({ open, onClose, institute, onSubmit }) => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  useEffect(() => {
    if (institute) {
      setFormState({
        name: institute.institution_name,
        email: institute.institution_email,
        phone: institute.institution_phone,
        address: institute.institution_address,
      });
    }
    return () => {
      setFormState({
        name: "",
        email: "",
        phone: "",
        address: "",
      });
    };
  }, [institute]);

  const [errors, setErrors] = useState({});

  const validationForm = () => {
    const newError = {};

    if (!formState.name) newError.name = "Institution Name is required.";
    if (!formState.email) {
      newError.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
      newError.email = "Invalid Email format";
    }
    if (!formState.phone) {
      newError.phone = "Mobile number is required.";
    } else if (!/^\d{10}$/.test(formState.phone)) {
      newError.phone = "Mobile number must be 10 digits.";
    }
    if (!formState.address) newError.address = "Address is required";

    setErrors(newError);

    return Object.keys(newError).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationForm()) {
      onSubmit(formState);
      onClose();
    }
  };

  return (
    <>
      <Popup open={open} onClose={onClose} className="add-popup-container">
        <div className="add-course-div">
          <form>
            <Box className="popup-box">
              <div className="heading">
                {institute ? "Edit Institute" : "Add Institution"}
              </div>
              <div className="course-container">
                <TextField
                  className="textfields"
                  name="name"
                  label="Institution Name"
                  variant="outlined"
                  size="small"
                  value={formState.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <TextField
                  className="textfields"
                  name="email"
                  label="Institution Email"
                  variant="outlined"
                  size="small"
                  value={formState.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <TextField
                  className="textfields"
                  name="phone"
                  label="Institution Phone"
                  variant="outlined"
                  size="small"
                  value={formState.phone}
                  onChange={handleChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
                <TextField
                  className="textfields"
                  name="address"
                  label="Institution Address"
                  variant="outlined"
                  size="small"
                  value={formState.address}
                  onChange={handleChange}
                  error={!!errors.address}
                  helperText={errors.address}
                />
              </div>
              <div className="submit-banner-button-container">
                <button type="submit" onClick={handleSubmit}>
                  {institute ? "Edit" : "Submit"}
                </button>
                <button type="button" onClick={onClose}>
                  Cancel
                </button>
              </div>
            </Box>
          </form>
        </div>
      </Popup>
    </>
  );
};

export default InstitutionForm;
