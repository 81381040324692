import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AppConstants from "./../AppConstants";
import MyAppBar from "./appbar/my_appbar";
import Logo from "../../assets/WINSCORE.svg";
import Menu from "./menu/menu";
import "./home.css";
import Banner from "./content/banner/banner";
import Faculty from "./content/faculty/faculty";
import MainScreen from "./content/course/main_screen";
import RecordedCls from "./content/recorded_cls/recorded_cls";
import MainExam from "./content/exam_main/main_exam";
import CoursePurchase from "./content/course_purchase/course_purchase";
import CourseProgres from "./content/course_progres/course_progres";
import Refferal from "./content/refferal/refferal";
import NoteScreen from "./content/notes/note_screen";
import QuestionScreen from "./content/q_n_a/q_n_a_screen";
import ChapterScreen from "./content/chapter/chapter_screen";
import InstitutionScreen from "./content/institution/institution_screen/institution_screen";

const openedMixin = (theme) => ({
  width: AppConstants.drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: AppConstants.drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Home = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedItemFromMenu, setSelectedItemFromMenu] = useState(0);

  const handleSelectedItemChange = (selectedItem) => {
    setSelectedItemFromMenu(selectedItem);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <MyAppBar
        open={open}
        selectedItemFromMenu={selectedItemFromMenu}
        sendDrawerOpen={handleDrawerOpen}
      />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img alt="logo" src={Logo} className="brand-logo me-3" />
          <span className="win">WIN</span>
          <span className="score">SCORE</span>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Menu open={open} sendSelectedItemToParent={handleSelectedItemChange} />
        <Divider />
        <List></List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {(() => {
          switch (selectedItemFromMenu) {
            case 0:
              return <MainScreen />;

            case 1:
              return <ChapterScreen />;
            case 2:
              return <Faculty />;
            case 3:
              return <RecordedCls />;
            case 4:
              return <MainExam />;
            case 5:
              return <NoteScreen />;

            case 6:
              return <QuestionScreen />;
            case 7:
              return <CoursePurchase />;

            case 8:
              return <CourseProgres />;
            case 9:
              return <Refferal />;
            case 10:
              return <Banner />;
              case 11:
                return <InstitutionScreen />;
            default:
              return <MainScreen />;
          }
        })()}
      </Box>
    </Box>
  );
};

export default Home;
