
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import './progress.css';
import config from '../../../../../../network/auth';
import axios from '../../../../../../network/api';
import { baseUrl } from '../../../../../../network/constants/constants';
import AppConstants from '../../../../../AppConstants';

const Progress = ({ studentId }) => {
  const [examProgress, setExamProgress] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchStudentProgress = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/student-exam-Webprogress/${studentId}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const data = Array.isArray(response.data) ? response.data : [response.data];
        setExamProgress(data);
        // console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Personal Details error:", error);
        setLoading(false);
        setExamProgress([]);
      });
  };

  useEffect(() => {
    fetchStudentProgress();
  }, [studentId]);

  return (
    <div className="exam-progress-container">
      
      {loading ? (
        <div className="loading-spinner">
          <CircularProgress />
        </div>
      ) : (
        examProgress.length > 0 ? (
          examProgress.map((course, courseIndex) => (
            <Card key={courseIndex} style={{ marginBottom: '40px' }}>
              <Typography variant="h5" component="div" className="course-name">
                {course.course}
              </Typography>
              <div className="exam-progress-card-container">
                {course.subjects.map((subject, subjectIndex) => {
                  const data = {
                    labels: ['Correct', 'Incorrect', 'Unanswered'],
                    datasets: [
                      {
                        label: 'Percentage',
                        data: [subject.correct_percentage, subject.incorrect_percentage, subject.unanswered_percentage],
                        backgroundColor: ['#796dec', '#ed655b', '#9e9e9e'],
                        hoverOffset: 4,
                      },
                    ],
                  };

                  return (
                    <Card key={subjectIndex} className="exam-prgs-card">
                      <CardContent>
                        <Typography variant="h6" component="div">
                          {subject.subject}
                        </Typography>
                        <Pie data={data} />
                      </CardContent>
                    </Card>
                  );
                })}
              </div>
            </Card>
          ))
        ) : (
          <Typography>No exam progress available</Typography>
        )
      )}
    </div>
  );
};

export default Progress;



