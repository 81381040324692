// with pagination -first code

import React, { useState, useEffect } from "react";
import "./recorded_cls.css";
import { Container, Row, Col } from "react-bootstrap";
import RecordedClsComponent from "./component/rec_cls_component";
import { baseUrl } from "../../../../network/constants/constants";
import config from "../../../../network/auth";
import axios from "axios";
import Popup from "reactjs-popup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { MdOutlineFileUpload } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import AppConstants from "../../../AppConstants";

const RecordedCls = () => {
  const [loading, setLoading] = useState(false);
  const [recordedClsDetails, setRecordedClsDetails] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [newRecDetails, setNewRecDetails] = useState({
    recorded_class_name: "",
    recorded_class_duration: "",
    recorded_class_video: null,
    subject: "",
    chapter:"",
  });
  const [validationErrors, setValidationErrors] = useState({
    recorded_class_name: "",
    recorded_class_duration: "",
    recorded_class_video: "",
    subject: "",
    chapter:"",
  });
  const [selectedFileName, setSelectedFileName] = useState("");

  const validateForm = () => {
    const errors = {};

    
    if (!newRecDetails.recorded_class_name.trim()) {
      errors.recorded_class_name = "Recorded class name is required.";
    }

   
    const durationRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    if (!durationRegex.test(newRecDetails.recorded_class_duration)) {
      errors.recorded_class_duration =
        "Please enter a valid duration in the format HH:MM:SS.";
    }

    
    if (!newRecDetails.recorded_class_video) {
      errors.recorded_class_video = "Please upload a video file.";
    } else if (
      !["video/mp4", "video/mkv", "video/avi", "video/mov"].includes(
        newRecDetails.recorded_class_video.type
      )
      
    ) {
      errors.recorded_class_video = "Invalid video format. Please upload a valid video file.";
    }

   
    if (!newRecDetails.subject) {
      errors.subject = "Please select a subject.";
    }
    if (!newRecDetails.chapter) {
      errors.subject = "Please select a chapter.";
    }

    setValidationErrors(errors);

   
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event, close) => {
    event.preventDefault();
    if (validateForm()) {
      handleAddRecVideo(close);
    }
  };

  const handleSubjectChange = (e) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      subject: "",
    }));
    
    setNewRecDetails({
      ...newRecDetails,
      subject: e.target.value,
    });
  };

  const handleChapterChange = (e) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      chapter: "",
    }));
    
    setNewRecDetails({
      ...newRecDetails,
      chapter: e.target.value,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", 
    }));
    setNewRecDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]; 
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      recorded_class_video: "",
    }));

    setNewRecDetails((prevDetails) => ({
      ...prevDetails,
      recorded_class_video: file,
    }));
    setSelectedFileName(file.name);
  };

 
  // delete
  const deleteRecordedCls = (recoededId) => {
    setLoading(true);

    axios
      .delete(`${baseUrl}app/recorded-classes/${recoededId}/delete/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setRecordedClsDetails((prev) =>
          prev.filter((recorded) => recorded.id !== recoededId)
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Delete Recorded Error:", error);
        setLoading(false);
      });
  };

  // post video
  const handleAddRecVideo = (close) => {
    if (!newRecDetails.recorded_class_video) {
      console.error("Video file is not selected");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("recorded_class_name", newRecDetails.recorded_class_name);
    formData.append(
      "recorded_class_duration",
      newRecDetails.recorded_class_duration
    );
    formData.append("subject", newRecDetails.subject);
    formData.append("chapter", newRecDetails.chapter);
    formData.append("recorded_class_video", newRecDetails.recorded_class_video);

    axios
      .post(`${baseUrl}app/recorded-classes/upload/`, formData, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log("Upload response:", response);
        fetchRecordedCls();
        setNewRecDetails({
          recorded_class_name: "",
          recorded_class_duration: "",
          recorded_class_video: null,
          subject: "",
        });
        setSelectedFileName("")
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error(
          "Add Recorded Class Error:",
          error.response || error.message
        );
        setLoading(false);
      });
  };

  // edit
  const handleSubmitEdit = (recVieoId, editedrecVideo, close) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("recorded_class_name", editedrecVideo.recorded_class_name);
    formData.append(
      "recorded_class_duration",
      editedrecVideo.recorded_class_duration
    );
    formData.append("subject", editedrecVideo.subject);
    formData.append("chapter", editedrecVideo.chapter);

    if (editedrecVideo.recorded_class_video instanceof File) {
      formData.append(
        "recorded_class_video",
        editedrecVideo.recorded_class_video
      );
    }

    axios
      .put(
        `${baseUrl}app/recorded-classes/${recVieoId}/edit/`,
        formData,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        // console.log("edited response:", response.data);
        fetchRecordedCls();
        setSelectedFileName("")
        setLoading(false);
        close();
      })
      .catch((error) => {
        console.error(
          "edited Recorded Class Error:",
          error.response || error.message
        );
        setLoading(false);
      });
  };

  const fetchSubjectList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/courses/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const subjects = response.data.results.flatMap((course) =>
          course.course_subjects.map((subject) => ({
            id: subject.subject_id,
            name: subject.Subject_name,
          }))
        );
        // console.log(subjectList)
        setSubjectList(subjects);
      })
      .catch((error) => {
        console.error(
          "Error fetching subject list:",
          error.response || error.message
        );
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000); 
      });
  };

  const fetchChapterList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/chapters/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const chapterList = response.data.map((chapter) => ({
          id: chapter.id,
          session: chapter.session,
        }));
        setChapterList(chapterList);
      })
      .catch((error) => {
        console.error(
          "Error fetching chapter list:",
          error.response || error.message
        );
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000); 
      });
  };

  const fetchRecordedCls = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/recorded-classes/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setRecordedClsDetails(response.data);
        
      })
      .catch((error) => {
        console.error(
          "Fetch RecordedCls Error:",
          error.response || error.message
        );
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000); 
      });
  };

  useEffect(() => {
    fetchRecordedCls();
    fetchSubjectList();
    fetchChapterList()
  }, []);

  const groupRecordedClassesBySubject = () => {
    return recordedClsDetails.reduce((acc, recordedClass) => {
      const subjectId = recordedClass.subject;
     
      if (!acc[subjectId]) {
        acc[subjectId] = [];
      }
      acc[subjectId].push(recordedClass);
      return acc;
    }, {});
  };

  const groupedRecordedClasses = groupRecordedClassesBySubject();

  // console.log("Grouped Recorded Classes:", groupedRecordedClasses);

  return (
    <div className="main-container">
      <Container fluid>
      <h2 className="accordion-head m-3">Subjects with Recorded class</h2>
        {loading ? (
          <Spinner className="loader-spinner"  />
        ) : Object.keys(groupedRecordedClasses).length > 0 ? (
          <Accordion>
            {Object.keys(groupedRecordedClasses).map((subjectId, idx) => {
              const subjectName =
                subjectList.find(
                  (subject) => subject.id === parseInt(subjectId)
                )?.name || "Unknown Subject";

              return (
                <Accordion.Item eventKey={idx.toString()} key={subjectId}>
                  <Accordion.Header>{subjectName}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      {groupedRecordedClasses[subjectId].map((recordedCls) => (
                        <Col lg={6} key={recordedCls.recorded_class_id}>
                          <RecordedClsComponent
                            key={recordedCls.recorded_class_id}
                            recorded_class_id={recordedCls.recorded_class_id}
                            recorded_class_name={
                              recordedCls.recorded_class_name
                            }
                            recorded_class_duration={
                              recordedCls.recorded_class_duration
                            }
                            recorded_class_video={
                              recordedCls.recorded_class_video
                            }
                            chapterId={
                              recordedCls.chapter
                            }
                            subject_name={subjectName}
                            subjectList={subjectList}
                            subjectId={recordedCls.subject}
                            chapterList={chapterList}
                            onEditRecVideo={handleSubmitEdit}
                            onDelete={deleteRecordedCls}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        ) : (
          <div className="no-records-message">No recorded classes found.</div>
        )}
        <Popup
          className="add-popup-container"
          trigger={
            <button className="btn-add" disabled={loading}>
              + Add Recorded Class Details
            </button>
          }
          modal
          nested
        >
          {(close) => (
            <div className="add-course-div">
              <form onSubmit={(e) => handleSubmit(e, close)}>
                <Box className="popup-box">
                  <div className="heading">Add Recorded Class Details</div>
                  <div className="course-container">
                    <TextField
                      className="textfields"
                      name="recorded_class_name"
                      label="Class Name"
                      variant="outlined"
                      size="small"
                      value={newRecDetails.recorded_class_name}
                      onChange={handleInputChange}
                      disabled={loading}
                      error={!!validationErrors.recorded_class_name}
                      helperText={validationErrors.recorded_class_name}
                    />
                    <TextField
                      className="textfields"
                      name="recorded_class_duration"
                      label="Class Duration"
                      variant="outlined"
                      size="small"
                      
                      value={newRecDetails.recorded_class_duration}
                      onChange={handleInputChange}
                      disabled={loading}
                      error={!!validationErrors.recorded_class_duration}
                      helperText={validationErrors.recorded_class_duration}
                    />
                    <select
                      name="subject"
                      className="form-control"
                      value={newRecDetails.subject}
                      onChange={handleSubjectChange}
                      disabled={loading}
                    >
                      <option value="">Select Subject</option>
                      {subjectList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {validationErrors.subject && (
                      <div className="error-text">
                        {validationErrors.subject}
                      </div>
                    )}
                    <select
                      name="chapter"
                      className="form-control"
                      value={newRecDetails.chapter}
                      onChange={handleChapterChange}
                      disabled={loading}
                    >
                      <option value="">Select Chapter</option>
                      {chapterList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.session}
                        </option>
                      ))}
                    </select>
                    {validationErrors.subject && (
                      <div className="error-text">
                        {validationErrors.chapter}
                      </div>
                    )}
                    <span>Recorded video</span>
                    <label className="add-file-upload">
                      <input
                        className="add-img-file"
                        name="recorded_class_video"
                        type="file"
                        onChange={handleFileChange}
                        disabled={loading}
                      />
                      <MdOutlineFileUpload className="upload-icon" />
                    </label>
                    {selectedFileName && (
                    <div className="file-name-display">
                      Selected File: {selectedFileName}
                    </div>
                  )}
                    {validationErrors.recorded_class_video && (
                      <div className="error-text">
                        {validationErrors.recorded_class_video}
                      </div>
                    )}
                  </div>
                  <div className="submit-banner-button-container">
                    <button type="submit" disabled={loading}>
                      Submit
                    </button>
                    <button type="button" onClick={close} disabled={loading}>
                      Cancel
                    </button>
                  </div>
                </Box>
              </form>
            </div>
          )}
        </Popup>
      </Container>
    </div>
  );
};

export default RecordedCls;



























