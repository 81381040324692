import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import Popup from "reactjs-popup";
import Autocomplete from "@mui/material/Autocomplete";

const StudentForm = ({
  open,
  onClose,
  onSubmit,
  students,
  institutionList,
  courseList,
}) => {
  const simplifiedInstitution = institutionList.map((item) => ({
    id: item.id,
    institution_name: item.institution_name,
  }));
  const [formState, setFormState] = useState({
    studentName: "",
    studentPhone: "",
    studentEmail: "",
    studentAddress: "",
    class: "",
    parentPhone: "",
    parentEmail: "",
    studentInstitution: "",
    course: "",
    referralId: "",
  });

  useEffect(() => {
    if (students) {
      setFormState({
        studentName: students.student_name,
        studentPhone: students.student_phone,
        studentEmail: students.student_email,
        studentAddress: students.student_address,
        class: students.student_class,
        parentPhone: students.student_parent_phone,
        parentEmail: students.student_parent_email,
        studentInstitution: students.student_institution,
      });
    }
    return () => {
      setFormState({
        studentName: "",
        studentPhone: "",
        studentEmail: "",
        studentAddress: "",
        class: "",
        parentPhone: "",
        parentEmail: "",
        studentInstitution: "",
      });
    };
  }, [students]);

  const [errors, setErrors] = useState({});

  const validationForm = () => {
    const newError = {};

    if (!formState.studentName)
      newError.studentName = "Student Name is required";
    if (!formState.studentPhone) {
      newError.studentPhone = "Mobile number is required.";
    } else if (!/^\d{10}$/.test(formState.studentPhone)) {
      newError.studentPhone = "Mobile number must be 10 digits.";
    }
    if (!formState.studentEmail) {
      newError.studentEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formState.studentEmail)) {
      newError.studentEmail = "Invalid Email format";
    }
    if (!formState.studentAddress)
      newError.studentAddress = "Student Address is required";
    if (!formState.class) newError.class = "class is required";
    if (!formState.parentPhone) {
      newError.parentPhone = "Mobile number is required.";
    } else if (!/^\d{10}$/.test(formState.parentPhone)) {
      newError.parentPhone = "Mobile number must be 10 digits.";
    }
    if (!formState.parentEmail) {
      newError.parentEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formState.studentEmail)) {
      newError.parentEmail = "Invalid Email format";
    }
    if (!formState.studentInstitution)
      newError.studentInstitution = "Institution Name is required";

    setErrors(newError);
    return Object.keys(newError).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationForm()) {
      onSubmit(formState); 
      
     
      setFormState({
        studentName: "",
        studentPhone: "",
        studentEmail: "",
        studentAddress: "",
        class: "",
        parentPhone: "",
        parentEmail: "",
        studentInstitution: "",
        course: "",
        referralId: "",
      });
      setErrors({}); 
      onClose(); 
    }
  };
  

  return (
    <>
      <Popup open={open} onClose={onClose} className="add-popup-container">
        <div className="add-course-div">
          <form>
            <Box className="popup-box">
              <div className="heading">
                {students ? "Edit Student" : "Add Student"}{" "}
              </div>
              <div className="course-container">
                <TextField
                  className="textfields"
                  name="studentName"
                  value={formState.studentName}
                  label="Student Name"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  error={!!errors.studentName}
                  helperText={errors.studentName}
                />
                <TextField
                  className="textfields"
                  name="studentPhone"
                  value={formState.studentPhone}
                  label="Student Phone"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  error={!!errors.studentPhone}
                  helperText={errors.studentPhone}
                />
                <TextField
                  className="textfields"
                  name="studentEmail"
                  value={formState.studentEmail}
                  label="Student Email"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  error={!!errors.studentEmail}
                  helperText={errors.studentEmail}
                />
                <TextField
                  className="textfields"
                  name="studentAddress"
                  value={formState.studentAddress}
                  label="Student Address"
                  variant="outlined"
                  size="small"
                  multiline
                  rows={2}
                  onChange={handleChange}
                  error={!!errors.studentAddress}
                  helperText={errors.studentAddress}
                />
                <TextField
                  className="textfields"
                  name="class"
                  value={formState.class}
                  label="Class"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  error={!!errors.class}
                  helperText={errors.class}
                />
                <TextField
                  className="textfields"
                  name="parentPhone"
                  value={formState.parentPhone}
                  label="Parent Phone"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  error={!!errors.parentPhone}
                  helperText={errors.parentPhone}
                />
                <TextField
                  className="textfields"
                  name="parentEmail"
                  value={formState.parentEmail}
                  label="Parent Email"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  error={!!errors.parentEmail}
                  helperText={errors.parentEmail}
                />
                <Autocomplete
                  className="textfields"
                  disablePortal
                  options={simplifiedInstitution}
                  getOptionLabel={(option) => option.institution_name}
                  value={
                    simplifiedInstitution.find(
                      (institution) =>
                        institution.id === formState.studentInstitution
                    ) || null
                  }
                  onChange={(event, value) => {
                    setFormState((prevData) => ({
                      ...prevData,
                      studentInstitution: value ? value.id : "",
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      studentInstitution: "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Institution"
                      variant="outlined"
                      size="small"
                      error={!!errors.studentInstitution}
                      helperText={errors.studentInstitution}
                    />
                  )}
                />
                {students ? (
                  <></>
                ) : (
                  <>
                  <select
                    name="course"
                    className="form-control"
                    value={formState.course}
                    onChange={handleChange}
                  >
                    <option value="">Select Course</option>
                    {courseList.map((option) => (
                      <option key={option.course_id} value={option.course_id}>
                        {option.course_name}
                      </option>
                    ))}
                  </select>
                   <TextField
                   className="textfields"
                   name="referralId"
                   value={formState.referralId}
                   label="Referral Id"
                   variant="outlined"
                   size="small"
                   onChange={handleChange}
                 />
                 </>
                 )} 
              </div>
              <div className="submit-banner-button-container">
                <button type="submit" onClick={handleSubmit}>
                  {students ? "Edit" : "Submit"}
                </button>
                <button type="button" onClick={onClose}>
                  Cancel
                </button>
              </div>
            </Box>
          </form>
        </div>
      </Popup>
    </>
  );
};

export default StudentForm;
