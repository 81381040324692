import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TabContext from "@mui/lab/TabContext";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/constants";
import "./course_purchase.css";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import { Snackbar, Alert } from "@mui/material";
import AppConstants from "../../../AppConstants";

const CoursePurchase = () => {
  const [purchasedDetails, setPurchasedDetails] = useState([]);
 
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredDetails, setFilteredDetails] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [tableValue, setTableValue] = useState("1");
  const [loading, setLoading] = useState(false);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const reloadFunction = async () => {
    setLoading(true);
    try {
      let allPurchasingData = [];
      let nextPageUrl = `${baseUrl}app/purchased-course-list/`;

      while (nextPageUrl) {
        const response = await axios.get(nextPageUrl, {
          headers: {
            Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
           
          },
        });
        allPurchasingData = [...allPurchasingData, ...response.data.results];
        nextPageUrl = response.data.next;
      }
      setPurchasedDetails(allPurchasingData);
      setFilteredDetails(allPurchasingData);
    } catch (error) {
      console.error("Error fetching purchased course list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reloadFunction();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 10 },
    {
      field: "order_paid_date",
      headerName: "Order Paid Date",
      width: 200,
      renderCell: (params) => {
        const date = new Date(params.value);
        const hours = date.getHours();
        const ampm = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12;
        return `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} ${
          hours12 === 0 ? 12 : hours12
        }:${date.getMinutes()}:${date.getSeconds()} ${ampm}`;
      },
    },
    {
      field: "student_name",
      headerName: "Student Name",
      width: 150,
    },
    {
      field: "course_name",
      headerName: "Course Name",
      width: 270,
    },
    {
      field: "course_image",
      headerName: "Image",
      width: 100,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          <img src={params.value} alt="Course Image" width="60" height="60" />
        </a>
      ),
    },
    {
      field: "course_validity",
      headerName: "Course Validity",
      type: "number",
      width: 150,
    },
    {
      field: "expiry_date",
      headerName: "Expiry Date",
      width: 200,
      renderCell: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString() + " " + date.toLocaleTimeString();
      },
    },
    {
      field: "faculty_names",
      headerName: "Faculty Names",
      width: 250,
      renderCell: (params) => params.value.join(", "),
    },
    {
      field: "Purchase_amount",
      headerName: "Purchase Amount(dollar)",
      type: "number",
      width: 150,
    },
    {
      field: "order_payment_id",
      headerName: "Order Payment ID",
      width: 200,
    },

    {
      field: "status",
      headerName: "Status",
      width: 120,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
    },
    {
      field: "student_address",
      headerName: "Student Address",
      width: 250,
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
    },
  ];

  const exportToExcel = () => {
    const formattedDetails = filteredDetails.map((detail) => {
      const { user, Purchase_course, course_progress, purchase_id, ...rest } =
        detail;

      return {
        ...rest,
        faculty_names: detail.faculty_names
          ? detail.faculty_names.join(", ")
          : "N/A",
        expiry_date: new Date(detail.expiry_date).toLocaleString(),
        order_paid_date: new Date(detail.order_paid_date).toLocaleString(),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedDetails);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscriptions");
    XLSX.writeFile(workbook, "course-purchase.xlsx");
    setSnackbarMessage("filtered data successfully exported");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  const handleFilter = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    end.setHours(23, 59, 59, 999);

    const filtered = purchasedDetails.filter((detail) => {
      const orderDate = new Date(detail.order_paid_date);
      return orderDate >= start && orderDate <= end;
    });

    setFilteredDetails(filtered);
  };

  const handleReset = () => {
    setFilteredDetails(purchasedDetails);
    setStartDate("");
    setEndDate("");
    setSnackbarMessage("Filter reset.");
    setSnackbarSeverity("info");
    setSnackbarOpen(true);
  };

  return (
    <TabContext value={tableValue}>
      <Box className="box-container">
        <div className="download-excel">
          <div className="date-input-container">
            <input
              className="date-input"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />
            <input
              className="date-input"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
              min={startDate}
            />
            <button className="filter-btn" onClick={handleFilter}>
              Apply Range
            </button>
            <button className="reset-btn" onClick={handleReset}>
              Reset
            </button>
          </div>
          <Button
            variant="contained"
            className="d-flex justify-content-center"
            color="primary"
            onClick={exportToExcel}
            style={{ marginBottom: "10px" }}
          >
            <DownloadIcon /> Download
          </Button>
        </div>
        <DataGrid
          pageSize={20}
          autoHeight
          rows={filteredDetails}
          columns={columns}
          getRowId={(row) => row.purchase_id}
          loading={loading}
          editMode="row"
        />
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </TabContext>
  );
};

export default CoursePurchase;
