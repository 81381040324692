import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Popup from "reactjs-popup";

const InstitutionTable = ({ institution, handleEditClick, onDelete }) => {
  const [selectedInstitution, setSelectedInstitution] = useState(null);

  const handleDeleteClick = (category) => {
    setSelectedInstitution(category);
  };

  const confirmDelete = () => {
    if (selectedInstitution) {
      onDelete(selectedInstitution.id);
      setSelectedInstitution(null);
    }
  };
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "institution_name", headerName: "Institution Name", width: 200 },
    { field: "institution_email", headerName: "Institution Email", width: 200 },
    { field: "institution_phone", headerName: "Institution Phone", width: 200 },
    {
      field: "institution_address",
      headerName: "Institution Address",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <div className="action-btn">
          <Button
            variant="outlined"
            style={{ marginRight: "5px" }}
            color="primary"
            onClick={() => handleEditClick(params)}
          >
            <EditIcon />
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleDeleteClick(params.row)}
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: { xs: 650, sm: 1500 },
          bgcolor: "background.paper",
        }}
      >
        <DataGrid
          rows={institution}
          columns={columns}
          disableSelectionOnClick
        />
      </Box>
      {selectedInstitution && (
        <Popup
          className="popup-container"
          contentStyle={{
            borderRadius: "20px",
            backgroundColor: "white",
          }}
          open={true}
          onClose={() => setSelectedInstitution(null)}
          modal
          nested
        >
          {(close) => (
            <div className="delete-img-div">
              <h3 className="delete-img-heading">Are you sure?</h3>
              <p className="delete-img-p">Do you really want to delete?</p>
              <div className="delete-btn-div">
                <button
                  onClick={() => {
                    confirmDelete();
                    close();
                  }}
                >
                  Delete
                </button>
                <button onClick={close}>Cancel</button>
              </div>
            </div>
          )}
        </Popup>
      )}
    </>
  );
};

export default InstitutionTable;
