import "./../login/login.css";
import "./otp.css";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "react-bootstrap/";
import Logo from "./../../assets/WINSCORE.svg";
import { TextField, Button } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import axios from "../../network/api";
import { baseUrl } from "../../network/constants/constants";
import AppConstants from "../AppConstants";

function Otp() {
  const location = useLocation();
  const email = location.state?.data.email;
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [serverError, setServerError] = useState("");
  const navigate = useNavigate();

  const handleOTP = (event) => {
    setOTP(event.target.value);
    setServerError("");
  };

  const validateOTP = (otp) => {
    const regex = /^\d{6}$/;
    setOtpError(!regex.test(otp));
    return regex.test(otp);
  };

  const PostOTP = (props) => {
    setLoading(true);
    setServerError("");

    const payload = {
      email: email,
      otp: otp,
    };

    console.log("payload: " + JSON.stringify(payload));

    axios
      .post(baseUrl + "auth/validate/", payload)
      .then((response) => {
        setLoading(false);
        console.log(AppConstants.JWT, response.data["token"]);
        localStorage.setItem(AppConstants.JWT, response.data["token"]);
        navigate("../home", { replace: true });
      })
      .catch((error) => {
        console.log(error + "errrorrriiii");
        setLoading(false);
        if (error.response && error.response.status === 400) {
          
          setServerError("Invalid OTP. Please try again.");
        } else {
         
          setServerError("An error occurred. Please try again later.");
        }
      });
  };

  return (
    <section id="login">
      <div className="content">
        <div className="brand-container">
          <img alt="logo" src={Logo} className="logo" />
          <div className="business-name"><span className="win">WIN</span>SCORE Admin Panel</div>
        </div>
        <Card className="login-card card mt-5 rounded-4">
          <div className="login-title">OTP Verification</div>
          <TextField
            id="otp"
            className="mt-4"
            label="Enter OTP"
            variant="outlined"
            size="small"
            value={otp}
            onChange={handleOTP}
            error={otpError}
            helperText={otpError ? "Invalid OTP Number" : ""}
          />
          {serverError && <div className="error-message">{serverError}</div>}
          <Button
            variant="contained"
            className="login-btn mt-5 mb-3"
            onClick={() => {
              if (validateOTP(otp)) {
                setLoading(true);
                PostOTP();
              }
            }}
          >
            Submit
          </Button>
          <div className="not-number">
            {email}
            <div className="click-back-link" onClick={() => navigate("../", { replace: true })}>
              Not your Email? Click to Go Back
            </div>
          </div>
          <div className="spinner-container">
            <TailSpin
              className="mt-5"
              height="30"
              width="30"
              radius="1"
              color="#796dec"
              ariaLabel="loading"
              visible={loading}
            />
          </div>
        </Card>
      </div>

      <a href="tel:" className="contact">
        ⓘ Contact Us
      </a>
    </section>
  );
}

export default Otp;
