import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/constants";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./refferal.css";
import Button from "@mui/material/Button";
import * as XLSX from "xlsx";
import AppConstants from "../../../AppConstants";
import { Snackbar, Alert } from "@mui/material";

const Refferal = () => {
  const [referralReport, setReferralReport] = useState([]);
  const [facultyArray, setFacultyArray] = useState([]);
  const [studentArray, setStudentArray] = useState([]);

  const [filteredStudents, setFilteredStudents] = useState([]);

  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const fetchRefferalList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}app/referral-report/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
        },
      })
      .then((response) => {
        setReferralReport(response.data);
        processData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching referral list:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const processData = (data) => {
    const faculties = data.map((faculty) => ({
      faculty_name: faculty.faculty_name,
      faculty_referral_id: faculty.faculty_referral_id,
    }));
    const students = data.flatMap((faculty) =>
      faculty.students.map((student) => ({
        student_id: student.student_id,
        student_name: student.student_name,
        student_email: student.student_email,
        student_phone: student.student_phone,
        student_address: student.student_address,
        student_class: student.student_class,
        student_institution_name: student.student_institution_name,
        student_institution_email: student.student_institution_email,
        student_referral_id: student.student_referral_id,
        student_parent_phone: student.student_parent_phone,
        student_parent_email: student.student_parent_email,
        course_id: student.course_id,
        initially_purchased_course: student.initially_purchased_course,
        purchase_date: student.purchase_date,
        faculty_name: faculty.faculty_name,
        faculty_referral_id: faculty.faculty_referral_id,
      }))
    );
    setFacultyArray(faculties);
    setStudentArray(students);
    setFilteredStudents(students);
  };

  
  
  const handleFacultyChange = (facultyId) => {
    setSelectedFaculty(facultyId);
  
    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999);
  
    const filteredByFaculty = studentArray.filter((student) => {
      const orderDate = new Date(student.purchase_date);
      const isWithinDateRange =
        (!startDate || orderDate >= start) &&
        (!endDate || orderDate <= end);
      const matchesFaculty =
        !facultyId || student.faculty_referral_id === facultyId;
  
      return isWithinDateRange && matchesFaculty;
    });
  
    setFilteredStudents(filteredByFaculty);
  };
  
  const handleFilter = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    end.setHours(23, 59, 59, 999);
  
    const filteredByDate = studentArray.filter((student) => {
      const orderDate = new Date(student.purchase_date);
      const isWithinDateRange =
        (!startDate || orderDate >= start) &&
        (!endDate || orderDate <= end);
      const matchesFaculty =
        !selectedFaculty || student.faculty_referral_id === selectedFaculty;
  
      return isWithinDateRange && matchesFaculty;
    });
  
    setFilteredStudents(filteredByDate);
    setSnackbarMessage("Filtered Data");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setSelectedFaculty("");
    setFilteredStudents(studentArray);
    setSnackbarMessage("Filter reset.");
    setSnackbarSeverity("info");
    setSnackbarOpen(true);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredStudents);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscriptions");
    XLSX.writeFile(workbook, "referral-report.xlsx");
    setSnackbarMessage("filtered data successfully exported");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  }
  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchRefferalList();
  }, []);

  return (
    <div>
      <h2 className="faculty-refferal-head">Faculty Referral Search</h2>
      <FormControl className="form-control-refferal">
        <InputLabel id="faculty-select-label">Select Faculty Name</InputLabel>
        <Select
          labelId="faculty-select-label"
          id="faculty-select"
          value={selectedFaculty}
          onChange={(e) => handleFacultyChange(e.target.value)}
          label="Faculty Name"
        >
          <MenuItem value="">Not Select Faculty</MenuItem>
          {facultyArray.map((faculty) => (
            <MenuItem
              key={faculty.faculty_referral_id}
              value={faculty.faculty_referral_id}
            >
              {faculty.faculty_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box className="box-container">
        <div className="data-grid">
          <div className="download-excel">
            <div className="date-input-container">
              <input
                className="date-input"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
              />
              <input
                className="date-input"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate}
              />
              <button className="filter-btn" onClick={handleFilter}>Apply Range</button>
              <button className="reset-btn" onClick={handleReset}>Reset</button>
            </div>
            <Button
              variant="contained"
              className="d-flex justify-content-center"
              color="primary"
              style={{ marginBottom: "10px" }}
              onClick={exportToExcel}
            >
              Export as Excel
            </Button>
          </div>
          <DataGrid
            rows={filteredStudents}
            getRowId={(row) => row.student_id}
            columns={[
              { field: "student_id", headerName: "Student Id", width: 90 },
              {
                field: "purchase_date",
                headerName: "Purchase Date",
                width: 150,
                renderCell: (params) => {
                  const date = new Date(params.value);
                  const hours = date.getHours();
                  const ampm = hours >= 12 ? "PM" : "AM";
                  const hours12 = hours % 12;
                  return `${date.getDate()}/${
                    date.getMonth() + 1
                  }/${date.getFullYear()} ${
                    hours12 === 0 ? 12 : hours12
                  }:${date.getMinutes()}:${date.getSeconds()} ${ampm}`;
                },
              },
              { field: "student_name", headerName: "Student Name", width: 150 },
              {
                field: "student_email",
                headerName: "Student Email",
                width: 200,
              },
              { field: "student_class", headerName: "Class", width: 100 },
              { field: "student_address", headerName: "Address", width: 200 },
              { field: "student_phone", headerName: "Phone", width: 150 },
              {
                field: "student_parent_phone",
                headerName: "Parent Phone",
                width: 150,
              },
              {
                field: "student_parent_email",
                headerName: "Parent Email",
                width: 200,
              },
              {
                field: "student_referral_id",
                headerName: "Student Referral Id",
                width: 200,
              },
              {
                field: "initially_purchased_course",
                headerName: "Initially Purchased Course",
                width: 250,
              },

              {
                field: "student_institution_name",
                headerName: "Institution Name",
                width: 150,
              },
              {
                field: "student_institution_email",
                headerName: "Institution Email",
                width: 200,
              },
            ]}
            pageSize={5}
          />
        </div>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default Refferal;
