import React, { useEffect, useState } from "react";
import "./institution_screen";
import { baseUrl } from "../../../../../network/constants/constants";
import config from "../../../../../network/auth";
import axios from "../../../../../network/api";
import { Spinner } from "react-bootstrap";
import InstitutionTable from "../institution_component/institution_table";
import InstitutionForm from "../institution_component/institution_form";
import AppConstants from "../../../../AppConstants";

const InstitutionScreen = () => {
  const [institution, setInstitution] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [selectedInstitute, setSelectedInstitute] = useState(null);

  const fetchInstitution = () => {
    setLoading(true);
    axios
      .get(baseUrl + `app/institution-list-create/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setInstitution(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Institution Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchInstitution();
  }, []);

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleEditClick = (institution = null) => {
    setIsEdit(true);
    setSelectedInstitute(institution);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setIsEdit(false);
    setOpenForm(false);
    setSelectedInstitute(null);
  };

  const handleSubmit = (formState) => {
    setLoading(true);
    const form = new FormData();

    form.append("institution_name", formState.name);
    form.append("institution_email", formState.email);
    form.append("institution_phone", formState.phone);
    form.append("institution_address", formState.address);

    if (isEdit) {
      editData(form);
    } else {
      addData(form);
    }
  };
  const addData = (form) => {
    axios
      .post(`${baseUrl}app/institution-list-create/`, form, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        fetchInstitution();
      })
      .catch((error) => {
        console.error("Institute Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editData = (form) => {
    const id = selectedInstitute?.row.id;
    axios
      .patch(`${baseUrl}app/institution-get-edit-delete/${id}/`, form, {
        headers: {
          Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
          // "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        fetchInstitution();
      })
      .catch((error) => {
        console.error("Institute Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteInstitute = (InstituteId) => {
    setLoading(true);

    axios
      .delete(
        `${baseUrl}app/institution-get-edit-delete/${InstituteId}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem(AppConstants.JWT)}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        fetchInstitution();
      })
      .catch((error) => {
        console.error("Error deleting institution:", error);
        setLoading(false);
      });
  };

  return (
    <div className="main-container">
      <button className="btn-add" onClick={handleOpenForm}>
        + Add Institution
      </button>
      {loading ? (
        <div className="loader-container">
          <Spinner className="spinner-loader" />
        </div>
      ) : (
        <>
          <InstitutionTable
            institution={institution}
            loading={loading}
            handleEditClick={handleEditClick}
            onDelete={deleteInstitute}
          />

          <InstitutionForm
            open={openForm}
            onClose={handleCloseForm}
            institute={isEdit ? selectedInstitute?.row : null}
            onSubmit={handleSubmit}
          />
        </>
      )}
    </div>
  );
};

export default InstitutionScreen;
